<template>
  <v-row>
      <v-col>
          <v-card class="pa-4 rounded-xl">
              <v-card-title class="justify-center blue--text">{{ barChartByCategoryItem }}</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-select
                      outlined
                      class="rounded-lg"
                      v-model="barChartByCategoryItem"
                      :items="barChartByCategoryItems"
                      @change="calcBarChartValuesByCategory()">
                      </v-select>
                    </v-col>
                    <v-col v-if="!showPieChart" cols="3">
                      <v-select
                      outlined
                      class="rounded-lg"
                      v-model="categoryTopNo"
                      :items="topValuesTypes"
                      @change="getTopValues()">
                      </v-select>
                    </v-col>
                  </v-row>
                </v-container>
                  <Bar 
                  v-if="!showPieChart"
                  :options="chartOptions"
                  :data="barChartValuesByCategory"
                  />
                  <Pie 
                  v-if="showPieChart"
                  class="pie-chart"
                  :options="pieChartOptions"
                  :data="barChartValuesByCategory"
                  />
              </v-card-text>
          </v-card>
      </v-col>
  </v-row>
</template>
<script>
import { Bar } from 'vue-chartjs'
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "MetricBarChart",
  components: {
    Bar,
    Pie,
  },
  data() {
    return {
      chartOptions: {
          plugins: {
              legend: {
                  display: false,
              }
          },
          scales: {
              x: {
                  ticks: {
                      autoSkip: false,
                  }
              }
          },
          responsive: true,
      },
      pieChartOptions: {
          responsive: false,
      },
      showPieChart: true,
      barChartByCategoryItem: "Projects by category",
      barChartByCategoryItems: [
          "Projects by category",
          "Projects by rating",
          "Carbon sequestration by category (total tCO2e)",
          "Average carbon sequestration per project by category (tCO2e)",
          "Total price by category ($)",
          "Average price by category ($/tCO2e)",
          "Average permanence by category (years)",
          "Average negativity by category",
          "Top applicants by volume",
      ],
      allBarChartValuesByCategory: {},
      barChartValuesByCategory: {},
      topValuesTypes: [
        "Top 5",
        "Top 10",
        "Top 15",
        "Top 20",
        "Top 25",
      ],
      categoryTopNo: "Top 10",
    };
  },
  methods: {
    getTopValues() {
      let barChartItems = this.allBarChartValuesByCategory;
      let labels = structuredClone(barChartItems.labels)
      let data = structuredClone(barChartItems.datasets[0].data);
      let sortedData = data.slice(0).sort((a, b) => {
          return b - a;
      });
      let n = parseInt(this.categoryTopNo.split(" ")[1]);
      const nthHighestValue = sortedData[n - 1];
      let topNByValue = {};

      for (let [index, value] of data.entries()) {
        if (value >= nthHighestValue) {
          topNByValue[labels[index]] = value;
        }
      }

      labels = Object.keys(topNByValue);
      data = Object.values(topNByValue);
      this.barChartValuesByCategory = {
        labels: labels,
        datasets: [
          {
            label: this.chartLabel,
            backgroundColor: this.backgroundColor,
            data: data,
            borderRadius: 15,
          }
        ]
      };

      return topNByValue;
    },
    calcProjectsPerTag() {
      // if (this.$store.state.noOfProjectsPerTag) {
      //   noOfProjectsPerTag = this.$store.state.noOfProjectsPerTag;
      //   return
      // } else {
      //   // Insert the below code in this block.
      // }

      const allTags = this.$store.state.allTags;
      let noOfProjectsPerTag = {};
      
      for (let tag of allTags) {
        const projects = this.$store.state.allProjects.filter((project) => {
            return project.tags.includes(tag);
        });
        const noOfProjects = projects.length;
        noOfProjectsPerTag[tag] = noOfProjects;
      }

      this.$store.state.noOfProjectsPerTag = noOfProjectsPerTag;

      let labels = Object.keys(noOfProjectsPerTag);
      const chartLabel = "No of projects per tag";
      this.chartLabel = chartLabel;
      const backgroundColor = "#00acee";
      this.backgroundColor = backgroundColor;
      let data = Object.values(noOfProjectsPerTag);
      
      noOfProjectsPerTag = {
        labels: labels,
        datasets: [
          {
            label: chartLabel,
            backgroundColor: backgroundColor,
            data: data,
            borderRadius: 15,
          }
        ]
      };

      // Add alphabetical sorting.

      return noOfProjectsPerTag;
    },
    calcProjectsPerRating() {
        let noOfProjectsPerRating = {
            "1": "",
            "2": "",
            "3": "",
            "4": "",
            "5": "",
        }
        const ratings = Object.keys(noOfProjectsPerRating);

        for (let rating of ratings) {
            const projects = this.$store.state.allProjects.filter((project) => {
                return project.rating === rating;
            });
            const noOfProjects = projects.length;
            noOfProjectsPerRating[rating] = noOfProjects;
        }

        const labels = Object.keys(noOfProjectsPerRating);
        const chartLabel = "No of projects by rating";
        const backgroundColor = [
            "#cda1ff",
            "#b778ff",
            "#a759ff",
            "#9436ff",
            "#8112ff",
        ];
        const data = Object.values(noOfProjectsPerRating);
        noOfProjectsPerRating = {
            labels: labels,
            datasets: [
                {
                    label: chartLabel,
                    backgroundColor: backgroundColor,
                    data: data
                }
            ]
        };

        return noOfProjectsPerRating;
    },
    calcTotalVolumePerTag() {
        const allTags = this.$store.state.allTags;
        let totalVolumePerTag = {};

        for (let tag of allTags) {
            const projects = this.$store.state.allProjects.filter((project) => {
                return project.tags.includes(tag);
            });
            const totalVolume = projects.reduce((total, project) => {
                const volume = project.metrics[1].value;
                
                if (!isNaN(volume)) {
                    return total + volume;
                } else {
                    return total;
                }
            }, 0);

            if (totalVolume > 0) {
                totalVolumePerTag[tag] = totalVolume;
            }
        }

        this.$store.state.totalVolumePerTag = totalVolumePerTag;

        let labels = Object.keys(totalVolumePerTag);
        const chartLabel = "Total volume per tag (tCO2e)";
        this.chartLabel = chartLabel;
        const backgroundColor = "#FF5733";
        this.backgroundColor = backgroundColor;
        let data = Object.values(totalVolumePerTag);
        
        totalVolumePerTag = {
            labels: labels,
            datasets: [
                {
                    label: chartLabel,
                    backgroundColor: backgroundColor,
                    data: data,
                    borderRadius: 15,
                }
            ]
        };

        return totalVolumePerTag;
    },
    calcAverageVolumePerTag() {
        const allTags = this.$store.state.allTags;
        let averageVolumePerTag = {};

        for (let tag of allTags) {
            let noOfProjects = 0;
            const projects = this.$store.state.allProjects.filter((project) => {
                return project.tags.includes(tag);
            });
            const totalVolume = projects.reduce((total, project) => {
                const volume = project.metrics[1].value;
                
                if (!isNaN(volume)) {
                    noOfProjects += 1;
                    return total + volume;
                } else {
                    return total;
                }
            }, 0);

            if (totalVolume > 0) {
                const averageVolume = totalVolume / noOfProjects;
                averageVolumePerTag[tag] = averageVolume;
            }
        }

        this.$store.state.averageVolumePerTag = averageVolumePerTag;

        let labels = Object.keys(averageVolumePerTag);
        const chartLabel = "Average volume per project by category (tCO2e)";
        this.chartLabel = chartLabel;
        const backgroundColor = "#FF5733";
        this.backgroundColor = backgroundColor;
        let data = Object.values(averageVolumePerTag);

        averageVolumePerTag = {
            labels: labels,
            datasets: [
                {
                    label: chartLabel,
                    backgroundColor: backgroundColor,
                    data: data,
                    borderRadius: 15,
                }
            ]
        };

        return averageVolumePerTag;
    },
    calcTotalPricePerTag() {
        const allTags = this.$store.state.allTags;
        let totalPricePerTag = {};

        for (let tag of allTags) {
            const projects = this.$store.state.allProjects.filter((project) => {
                return project.tags.includes(tag);
            });
            const totalPrice = projects.reduce((total, project) => {
                const price = project.metrics[5].value * project.metrics[1].value;

                if (!isNaN(price)) {
                    return total + price;
                } else {
                    return total;
                }
            }, 0);

            if (totalPrice > 0) {
                totalPricePerTag[tag] = totalPrice;
            }
        }

        this.$store.state.totalPricePerTag = totalPricePerTag;

        let labels = Object.keys(totalPricePerTag);
        const chartLabel = "Total price per tag ($)";
        this.chartLabel = chartLabel;
        const backgroundColor = "#0BDA00";
        this.backgroundColor = backgroundColor;
        let data = Object.values(totalPricePerTag);
        totalPricePerTag = {
            labels: labels,
            datasets: [
                {
                    label: chartLabel,
                    backgroundColor: backgroundColor,
                    data: data,
                    borderRadius: 15,
                }
            ]
        };

        return totalPricePerTag;
    },
    calcAveragePricePerTag() {
        const allTags = this.$store.state.allTags;
        let averagePricePerTag = {};

        for (let tag of allTags) {
            const projects = this.$store.state.allProjects.filter((project) => {
                return project.tags.includes(tag);
            });
            let noOfProjectsForCurrentTag = 0;
            const totalPrice = projects.reduce((total, project) => {
                const price = project.metrics[5].value;

                if (!isNaN(price)) {
                    noOfProjectsForCurrentTag += 1
                    
                    return total + price;
                } else {
                    return total;
                }
            }, 0);

            if (totalPrice > 0) {
                averagePricePerTag[tag] = totalPrice / noOfProjectsForCurrentTag;
            }
        }

        this.$store.state.averagePricePerTag = averagePricePerTag;

        let labels = Object.keys(averagePricePerTag);
        const chartLabel = "Average price per tag ($)";
        this.chartLabel = chartLabel;
        const backgroundColor = "#00E3E4";
        this.backgroundColor = backgroundColor;
        let data = Object.values(averagePricePerTag);
        averagePricePerTag = {
            labels: labels,
            datasets: [
                {
                    label: chartLabel,
                    backgroundColor: backgroundColor,
                    data: data,
                    borderRadius: 15,
                }
            ]
        };

        return averagePricePerTag;
    },
    calcAveragePermanencePerTag() {
        const allTags = this.$store.state.allTags;
        let totalPermanencePerTag = {};

        for (let tag of allTags) {
            const projects = this.$store.state.allProjects.filter((project) => {
                return project.tags.includes(tag);
            });
            let noOfProjects = 0;
            const totalPermanence = projects.reduce((total, project) => {
                const permanence = project.metrics[3].value;

                if (!isNaN(permanence)) {
                    noOfProjects += 1;
                    return total + permanence;
                } else {
                    return total;
                }
            }, 0);

            if (totalPermanence > 0) {
                totalPermanencePerTag[tag] = totalPermanence / noOfProjects;
            }
        }

        this.$store.state.totalPermanencePerTag = totalPermanencePerTag;

        let labels = Object.keys(totalPermanencePerTag);
        const chartLabel = "Average permanence per tag (years)";
        this.chartLabel = chartLabel;
        const backgroundColor = "#C1E100";
        this.backgroundColor = backgroundColor;
        let data = Object.values(totalPermanencePerTag);
        totalPermanencePerTag = {
            labels: labels,
            datasets: [
                {
                    label: chartLabel,
                    backgroundColor: backgroundColor,
                    data: data,
                    borderRadius: 15,
                }
            ]
        };
        
        return totalPermanencePerTag;
    },
    calcAverageNegativityPerTag() {
        const allTags = this.$store.state.allTags;
        let totalNegativityPerTag = {};

        for (let tag of allTags) {
            const projects = this.$store.state.allProjects.filter((project) => {
                return project.tags.includes(tag);
            });
            let noOfProjects = 0;
            const totalNegativity = projects.reduce((total, project) => {
                const negativity = project.metrics[2].value;
                
                if (!isNaN(negativity)) {
                    noOfProjects += 1;
                    return total + negativity;
                } else {
                    return total;
                }
            }, 0);

            if (totalNegativity > 0) {
                totalNegativityPerTag[tag] = totalNegativity / noOfProjects;
            }
        }

        this.$store.state.totalNegativityPerTag = totalNegativityPerTag;

        let labels = Object.keys(totalNegativityPerTag);
        const chartLabel = "Average negativity per tag";
        this.chartLabel = chartLabel;
        const backgroundColor = "#7B00E1";
        this.backgroundColor = backgroundColor;
        let data = Object.values(totalNegativityPerTag);
        totalNegativityPerTag = {
            labels: labels,
            datasets: [
                {
                    label: chartLabel,
                    backgroundColor: backgroundColor,
                    data: data,
                    borderRadius: 15,
                }
            ]
        };

        return totalNegativityPerTag;
    },
    calcTopTenApplicantsByVolume() {
        const allApplicants = this.$store.state.allApplicants;
        let applicantsByVolume = {};
        
        for (let applicant of allApplicants) {
            const projects = this.$store.state.allProjects.filter((project) => {
                return project.applicant === applicant;
            });
            const totalVolume = projects.reduce((total, project)=> {
                const volume = project.metrics[1].value;
                
                if (!isNaN(volume)) {
                    return total + volume;
                } else {
                    return total;
                }
            }, 0);
            applicantsByVolume[applicant] = totalVolume;
        }

        let labels = Object.keys(applicantsByVolume);
        const chartLabel = "Top applicants by volume";
        this.chartLabel = chartLabel;
        const backgroundColor = "#444";
        this.backgroundColor = backgroundColor;
        let data = Object.values(applicantsByVolume);
        applicantsByVolume = {
            labels: labels,
            datasets: [
                {
                    label: chartLabel,
                    backgroundColor: backgroundColor,
                    data: data,
                    borderRadius: 15,
                }
            ]
        };

        return applicantsByVolume;
    },
    calcBarChartValuesByCategory() {
        const metric = this.barChartByCategoryItem;
        const projectsByCategory = "Projects by category";
        const projectsByRating = "Projects by rating";
        const carbonSequestrationByCategory = "Carbon sequestration by category (total tCO2e)";
        const averageCarbonSequestrationByCategory = "Average carbon sequestration per project by category (tCO2e)";
        const totalPriceByCategory = "Total price by category ($)";
        const averagePriceByCategory = "Average price by category ($/tCO2e)";
        const totalPermanenceByCategory = "Average permanence by category (years)";
        const totalNegativityByCategory = "Average negativity by category";
        const topTenApplicantsByVolume = "Top applicants by volume";
        this.showPieChart = false;
        
        if (metric === projectsByCategory) {
            this.allBarChartValuesByCategory = this.calcProjectsPerTag();
        } else if (metric === projectsByRating) {
            this.barChartValuesByCategory = this.calcProjectsPerRating();
            this.showPieChart = true;
        } else if (metric === carbonSequestrationByCategory) {
            this.allBarChartValuesByCategory = this.calcTotalVolumePerTag();
        } else if (metric === averageCarbonSequestrationByCategory) {
            this.allBarChartValuesByCategory = this.calcAverageVolumePerTag();
        } else if (metric === totalPriceByCategory) {
            this.allBarChartValuesByCategory = this.calcTotalPricePerTag();
        } else if (metric === averagePriceByCategory) {
            this.allBarChartValuesByCategory = this.calcAveragePricePerTag();
        } else if (metric === totalPermanenceByCategory) {
            this.allBarChartValuesByCategory = this.calcAveragePermanencePerTag();
        } else if (metric === totalNegativityByCategory) {
            this.allBarChartValuesByCategory = this.calcAverageNegativityPerTag();
        } else if (metric === topTenApplicantsByVolume) {
            this.allBarChartValuesByCategory = this.calcTopTenApplicantsByVolume();
        }

        if (metric !== projectsByRating) {
          this.getTopValues();
        }

        return true;
    },
  },
  beforeMount() {
    this.calcProjectsPerTag();
    this.calcTotalVolumePerTag();
    this.calcAverageVolumePerTag();
    this.calcTotalPricePerTag();
    this.calcAveragePricePerTag();
    this.calcAveragePermanencePerTag();
    this.calcAverageNegativityPerTag();
    this.calcBarChartValuesByCategory();
  },
}
</script>
<style scoped>
.pie-chart {
    width: 350px;
    margin: auto;
}
</style>