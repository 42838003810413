<template>
  <v-app>
    <AuxialiaryItems />
    <AppHeader />
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>
<script>
import AuxialiaryItems from './components/auxiliary/Auxialiary.vue';
import AppHeader from './components/header/Header.vue';

export default {
  components: {
    AuxialiaryItems,
    AppHeader,
  },
  created() {
    this.axios.defaults.withCredentials = true;
  },
}
</script>
<style scoped>
#app {
  background: #eee;
}
</style>