<template>
    <v-dialog v-model="$store.state.detailedView">
        <v-tabs
        v-model="tab"
        fixed-tabs>
            <v-tab>Basic</v-tab>
            <v-tab>Advanced</v-tab>
            <v-tab>Metrics</v-tab>
            <v-tab>Others</v-tab>
        </v-tabs>
        <v-tabs-items
        v-model="tab">
            <v-tab-item class="pa-8">
                <v-row>
                    <v-col>
                        <p><span class="font-weight-bold blue--text">ID:</span> {{ selectedProject.id }}</p>
                    </v-col>
                    <v-col>
                        <p><span class="font-weight-bold blue--text">Applicant:</span> {{ selectedProject.applicant }}</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <p><span class="font-weight-bold blue--text">Location:</span> {{ selectedProject.location.name }}</p>
                    </v-col>
                    <v-col>
                        <p><span class="font-weight-bold blue--text">Rating:</span><v-rating class="d-inline" :value="selectedProject.rating" readonly></v-rating></p>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item class="pa-8">
                <v-row>
                    <v-col>
                        <p class="text-justify"><span class="font-weight-bold blue--text">Description:</span> {{ selectedProject.description }}</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <p class="text-justify"><span class="font-weight-bold blue--text">Methods:</span> {{ selectedProject.methods }}</p>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item class="pa-8">
                <v-row v-for="metric of selectedProject.metrics"
                :key="metric.name">
                    <v-col>
                        <span class="font-weight-bold blue--text">{{ metric.name.charAt(0).toUpperCase() + metric.name.slice(1) }}:</span><v-rating class="d-inline" :length="metric.rating" :value="metric.rating" readonly></v-rating>
                        <p class="ps-6" v-if="metric.value"><span class="font-weight-bold blue--text">Quantity: </span>{{ metric.value }} {{ metric.units }}</p>
                        <p class="ps-6 text-justify" v-if="metric.notes"><span class="font-weight-bold blue--text">Notes: </span>{{ metric.notes }}</p>
                        <p class="ps-6 text-justify" v-if="metric.comments"><span class="font-weight-bold blue--text">Comments: </span>{{ metric.comments }}</p>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item class="pa-8">
                <div>
                    <h3 class="blue--text">Source:</h3>
                    <v-row class="mt-1 pa-4">
                        <v-col>
                            <span class="font-weight-bold blue--text">ID:</span> {{ selectedProject.source.id }}
                        </v-col>
                        <v-col>
                            <span class="font-weight-bold blue--text">Date:</span> {{ selectedProject.source.date }}
                        </v-col>
                        <v-col>
                            <span class="font-weight-bold blue--text">License:</span> {{ selectedProject.source.license }}
                        </v-col>
                    </v-row>
                    <v-row class="pa-4">
                        <v-col v-show="selectedProject.source.url">
                            <span class="font-weight-bold blue--text">Name:</span> <a :href="selectedProject.source.url" target="_blank" rel="noopener noreferrer">{{ selectedProject.source.name }}</a>
                        </v-col>
                        <v-col v-show="selectedProject.documentation.url">
                            <span class="font-weight-bold blue--text">Documentation:</span> <a :href="selectedProject.documentation.url" target="_blank" rel="noopener noreferrer">{{ selectedProject.documentation.name }}</a>
                        </v-col>
                    </v-row>
                </div>
                <div class="mt-12">
                    <h3 class="blue--text">Tags:</h3>
                    <v-row class="mt-1 pa-4">
                        <v-col>
                            <v-chip
                            v-for="tag of selectedProject.tags"
                            color="primary"
                            class="ms-1"
                            :key="tag">
                                {{ tag }}
                            </v-chip>
                        </v-col>
                    </v-row>
                </div>
                <div v-if="selectedProject.keywords" class="mt-12">
                    <h3 class="blue--text">Keywords:</h3>
                    <p class="pa-4">{{ selectedProject.keywords }}</p>
                </div>
                <div v-if="selectedProject.notes" class="mt-12">
                    <h3 class="blue--text">Notes:</h3>
                    <p class="pa-4 text-justify">{{ selectedProject.notes }}</p>
                </div>
                <div class="mt-12">
                    <h3 class="blue--text">Revisions:</h3>
                    <v-timeline class="pa-4">
                        <v-timeline-item 
                        v-for="revision of selectedProject.revisions"
                        :key="revision">
                            <p>{{ revision.date }}</p>
                            <p class="text-justify">{{ revision.note }}</p>
                        </v-timeline-item>
                    </v-timeline>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <v-card tile>
            <v-card-actions>
                <v-spacer></v-spacer>
                <ErrorBtn btnIcon="mdi-close" btnName="Close" @click.native="closeDetailedView()" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import ErrorBtn from '../../common/ErrorBtn.vue';

export default {
    name: 'DetailedView',
    props: [
        'selectedProject',
    ],
    components: {
        ErrorBtn,
    },
    data() {
        return {
            tab: null,
        };
    },
    methods: {
        closeDetailedView() {
            this.$store.dispatch('closeDetailedView')

            return true;
        },
    },
}
</script>