<template>
    <v-container>
        <CircularLoader />
        <SnackBar />
    </v-container>
</template>
<script>
import CircularLoader from './components/Loader.vue';
import SnackBar from './components/Snackbar.vue';

export default {
    name: 'AuxialiaryItems',
    components: {
        CircularLoader,
        SnackBar,
    },
}
</script>