<template>
    <v-navigation-drawer
    dark
    fixed
    temporary
    v-model="$store.state.drawer">
        <v-list>
            <v-list-item 
            link
            v-for="item of items" 
            :key="item.id"
            :to="item.link">
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
export default {
    name: 'SideNav',
    data() {
        return {
            items: [
                {
                    id: 0,
                    icon: "mdi-view-dashboard",
                    title: "Home",
                    link: "/",
                },
                // {
                //     id: 1,
                //     icon: "mdi-plus",
                //     title: "Add project",
                //     link: "/add",
                // },
                {
                    id: 2,
                    icon: "mdi-poll",
                    title: "Summary",
                    link: "/summary",
                },
            ],
        };
    },
}
</script>
<style lang="scss" scoped>
.v-navigation-drawer {
    background: #20374d;
}
.v-list {
    position: absolute;
    top: 50%;
    left: 0px;
    right: 0px;
    transform: translateY(-50%);

    .v-list-item--active {
        background: #00acee;
        color: #fff;
    }
}
</style>