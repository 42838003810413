<template>
  <v-card>
    <v-card-title>Add a new project</v-card-title>
    <v-form ref="form">
      <v-container>
        <h4>Basic project details</h4>
        <v-row>
          <v-col 
          v-for="field of basicDetails"
          :key="field.id"
          cols="6">
            <v-text-field
            :label="field.label"
            :placeholder="field.placeholder"
            :value="field.value"
            v-model="field.value"
            :readonly="field.readonly"
            required
            outlined />
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <h4>Source details</h4>
        <v-row>
          <v-col 
          v-for="field of sourceDetails"
          :key="field.id"
          cols="6">
            <v-text-field
            :label="field.label"
            :placeholder="field.placeholder"
            :value="field.value"
            v-model="field.value"
            :readonly="field.readonly"
            required
            outlined />
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <h4>Metric details</h4>
        <v-row>
          <v-col 
          v-for="field of sourceDetails"
          :key="field.id"
          cols="6">
            <v-text-field
            :label="field.label"
            :placeholder="field.placeholder"
            :value="field.value"
            v-model="field.value"
            :readonly="field.readonly"
            required
            outlined />
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col align="right">
            <v-btn color="primary" prepend-icon="mdi-vuetify">
              <v-icon left>mdi-check</v-icon>Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>
<script>
export default {
  name: 'AddProject',
  data() {
    return {
      basicDetails: [
        {
          id: 0,
          label: "ID",
          placeholder: "",
          value: "CDR_2023_01_01",
          type: "text",
          readonly: true,
        },
        {
          id: 1,
          label: "Name",
          placeholder: "Afforestation in Nigeria",
          value: "",
          type: "text",
          readonly: false,
        },
        {
          id: 2,
          label: "Description",
          placeholder: "Afforestation in Nigeria",
          value: "",
          type: "text",
          readonly: false,
        },
        {
          id: 3,
          label: "Rating",
          placeholder: "Afforestation in Nigeria",
          value: 0,
          type: "number",
          readonly: true,
        },
        {
          id: 4,
          label: "Keywords",
          placeholder: "Afforestation in Nigeria",
          value: "",
          type: "text",
          readonly: false,
        },
        {
          id: 5,
          label: "Methods",
          placeholder: "Afforestation in Nigeria",
          value: "",
          type: "text",
          readonly: false,
        },
        {
          id: 6,
          label: "Location",
          placeholder: "Afforestation in Nigeria",
          value: "",
          type: "text",
          readonly: false,
        },
        {
          id: 7,
          label: "Notes",
          placeholder: "Afforestation in Nigeria",
          value: "",
          type: "text",
          readonly: false,
        },
        {
          id: 8,
          label: "Documentation URL",
          placeholder: "Afforestation in Nigeria",
          value: "",
          type: "text",
          readonly: false,
        },
      ],
      sourceDetails: [
        {
          id: 0,
          label: "ID",
          placeholder: "Afforestation in Nigeria",
          value: "STRP00",
          type: "text",
          readonly: true,
        },
        {
          id: 1,
          label: "Name",
          placeholder: "Afforestation in Nigeria",
          value: "",
          type: "text",
          readonly: false,
        },
        {
          id: 2,
          label: "License",
          placeholder: "Afforestation in Nigeria",
          value: "",
          type: "text",
          readonly: false,
        },
        {
          id: 3,
          label: "URL",
          placeholder: "Afforestation in Nigeria",
          value: "",
          type: "text",
          readonly: false,
        },
      ],
    };
  },
}
</script>