<template>
  <v-row class="mb-4">
      <v-col>
          <v-card class="rounded-xl pa-4">
              <v-card-title class="justify-center blue--text">Metrics by category</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-select
                      outlined
                      class="rounded-lg"
                      v-model="category"
                      :items="categories"
                      @change="calcMetricsByCategory()">
                      </v-select>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container>
                  <v-row>
                      <v-col>
                          <v-card class="rounded-lg text-center green lighten-5">
                              <v-card-title class="justify-center blue--text">#Projects</v-card-title>
                              <v-card-text class="font-weight-bold text-h5">{{ noOfProjectsForCategory }}</v-card-text>
                          </v-card>
                      </v-col>
                      <v-col>
                          <v-card class="rounded-lg text-center green lighten-5">
                              <v-card-title class="justify-center blue--text">Carbon sequestration</v-card-title>
                              <v-card-text class="font-weight-bold text-h5">{{ carbonSequestrationForCategory }}<span v-if="carbonSequestrationForCategory !== '-'"> tCO2e</span></v-card-text>
                          </v-card>
                      </v-col>
                      <v-col>
                          <v-card class="rounded-lg text-center green lighten-5">
                              <v-card-title class="justify-center blue--text">Total price</v-card-title>
                              <v-card-text class="font-weight-bold text-h5"><span v-if="totalPriceForCategory !== '-'">$</span>{{ totalPriceForCategory }}</v-card-text>
                          </v-card>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col>
                          <v-card class="rounded-lg text-center green lighten-5">
                              <v-card-title class="justify-center blue--text">Average permanence</v-card-title>
                              <v-card-text class="font-weight-bold text-h5">{{ averagePermanenceForCategory }}<span v-if="averagePermanenceForCategory !== '-'"> years</span></v-card-text>
                          </v-card>
                      </v-col>
                      <v-col>
                          <v-card class="rounded-lg text-center green lighten-5">
                              <v-card-title class="justify-center blue--text">Average negativity</v-card-title>
                              <v-card-text class="font-weight-bold text-h5">{{ averageNegativityForCategory }}</v-card-text>
                          </v-card>
                      </v-col>
                      <v-col>
                          <v-card class="rounded-lg text-center green lighten-5">
                              <v-card-title class="justify-center blue--text">Average price ($/tCO2e)</v-card-title>
                              <v-card-text class="font-weight-bold text-h5"><span v-if="averagePriceForCategory !== '-'">$</span>{{ averagePriceForCategory }}</v-card-text>
                          </v-card>
                      </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
          </v-card>
      </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'MetricsByCategory',
  data() {
    return {
      category: "forests",
      categories: [],
      noOfProjectsForCategory: "-",
      carbonSequestrationForCategory: "-",
      totalPriceForCategory: "-",
      averagePermanenceForCategory: "-",
      averageNegativityForCategory: "-",
      averagePriceForCategory: "-",
    };
  },
  methods: {
    setCategories() {
      this.categories = this.$store.state.allTags;
    },
    calcMetricsByCategory() {
        const category = this.category;
        this.noOfProjectsForCategory = this.$store.state.noOfProjectsPerTag[category];

        this.carbonSequestrationForCategory = this.$store.state.totalVolumePerTag[category] || "-";
        
        if (this.carbonSequestrationForCategory !== "-") {
            this.carbonSequestrationForCategory = this.carbonSequestrationForCategory.toLocaleString('en-US', {maximumFractionDigits:2})
        }

        this.totalPriceForCategory = this.$store.state.totalPricePerTag[category] || "-";
        
        if (this.totalPriceForCategory !== "-") {
            this.totalPriceForCategory = this.totalPriceForCategory.toLocaleString('en-US', {maximumFractionDigits:2})
        }

        this.averagePriceForCategory = this.$store.state.averagePricePerTag[category] || "-";
        
        if (this.averagePriceForCategory !== "-") {
            this.averagePriceForCategory = this.averagePriceForCategory.toLocaleString('en-US', {maximumFractionDigits:2})
        }

        this.averagePermanenceForCategory = this.$store.state.totalPermanencePerTag[category] || "-";
        
        if (this.averagePermanenceForCategory !== "-") {
            this.averagePermanenceForCategory = this.averagePermanenceForCategory.toLocaleString('en-US', {maximumFractionDigits:2})
        }

        this.averageNegativityForCategory = this.$store.state.totalNegativityPerTag[category] || "-";

        if (this.averageNegativityForCategory !== "-") {
            this.averageNegativityForCategory = this.averageNegativityForCategory.toLocaleString('en-US', {maximumFractionDigits:2})
        }
    },
  },
  beforeMount() {
    this.setCategories();
    this.calcMetricsByCategory();
  },
}
</script>