import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../components/home/Home.vue';
import AddProject from '../components/add project/AddProject.vue';
import ProjectsSummary from '../components/summary/Summary.vue';
import LoginPage from '../components/login/Login.vue';
import store from '../store/index.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/add',
    name: 'add project',
    component: AddProject,
  },
  {
    path: '/summary',
    name: 'summary',
    component: ProjectsSummary,
  },
  {
    path: '/auth',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/*',
    redirect: '/',
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from ,next) => {
  if (to.name !== 'login' && !store.state.isAuthenticated) {
    next({ name: 'login' })
  } else if (to.name === 'login' && store.state.isAuthenticated) {
    next(false);
  } else {
    store.dispatch('startLoading');
    setTimeout(() => {
      next();
    }, 50);
  }
});

router.afterEach(() => {
  store.dispatch('endLoading');
});

export default router
