import { render, staticRenderFns } from "./PoweredByZasti.vue?vue&type=template&id=2385817c&scoped=true&"
import script from "./PoweredByZasti.vue?vue&type=script&lang=js&"
export * from "./PoweredByZasti.vue?vue&type=script&lang=js&"
import style0 from "./PoweredByZasti.vue?vue&type=style&index=0&id=2385817c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2385817c",
  null
  
)

export default component.exports