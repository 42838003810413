<template>
  <v-container>
    <v-container>
      <v-card class="rounded-xl pa-6 px-8 mb-8">
        <v-card-title class="blue-grey--text text--darken-1 justify-center">Apply filters</v-card-title>
        <v-card-text class="mt-4">
          <v-row>
            <v-col>
              <v-select
              class="rounded-lg"
              v-model="tags"
              label="Tags"
              :items="allTags"
              multiple
              small-chips
              dense
              deletable-chips
              outlined
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
              class="rounded-lg"
              v-model="locations"
              label="Location"
              :items="allLocations"
              multiple
              small-chips
              dense
              deletable-chips
              outlined
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-select
              class="rounded-lg"
              v-model="applicants"
              label="Applicant"
              :items="allApplicants"
              multiple
              small-chips
              dense
              deletable-chips
              outlined
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
              class="rounded-lg"
              v-model="sourceNames"
              label="Source name"
              :items="allSourceNames"
              multiple
              small-chips
              dense
              deletable-chips
              outlined
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-select
              class="rounded-lg"
              v-model="sourceLicenses"
              label="Source License"
              :items="allSourceLicenses"
              multiple
              small-chips
              dense
              deletable-chips
              outlined
              >
              </v-select>
            </v-col>
            <v-col>
              <v-rating
              v-model="projectRating"
              empty-icon="mdi-star-outline"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              hover
              dense
              length="5"
              size="40"
              color="blue"
              ></v-rating>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-combobox
              class="rounded-lg"
              v-model="description"
              label="Filter by keywords in description"
              multiple
              small-chips
              dense
              deletable-chips
              outlined
              >
              </v-combobox>
            </v-col>
            <v-col>
              <v-combobox
              class="rounded-lg"
              v-model="methods"
              label="Filter by keywords in methods"
              multiple
              small-chips
              dense
              deletable-chips
              outlined
              >
              </v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-slider
              label="Mechanism"
              v-model="mechanismVal"
              step="1"
              :min="minMechanismVal"
              :max="maxMechanismVal"
              color="blue"
              thumb-label="always">
              </v-slider>
            </v-col>
            <v-col>
              <v-slider
              label="Negativity"
              v-model="negativityVal"
              step="0.01"
              :min="minNegativityVal"
              :max="maxNegativityVal"
              color="blue"
              thumb-label="always">
              </v-slider>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="10">
              <v-slider
              label="Volume"
              v-model="volumeVal"
              step="1000"
              :min="minVolumeVal"
              :max="maxVolumeVal"
              color="blue"
              thumb-label="always">
              </v-slider>
            </v-col>
            <v-col cols="2">
              <v-text-field
              class="rounded-lg"
              outlined
              dense
              v-model="volumeVal">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-slider
              label="Permanence"
              v-model="permanenceVal"
              step="1"
              :min="minPermanenceVal"
              :max="maxPermanenceVal"
              color="blue"
              thumb-label="always">
              </v-slider>
            </v-col>
            <v-col>
              <v-slider
              label="Additionality"
              v-model="additionalityVal"
              step="1"
              :min="minAdditionalityVal"
              :max="maxAdditionalityVal"
              color="blue"
              thumb-label="always">
              </v-slider>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-slider
              label="Price ($ / tCO2e)"
              v-model="priceVal"
              step="0.01"
              :min="minPriceVal"
              :max="maxPriceVal"
              color="blue"
              thumb-label="always">
              </v-slider>
            </v-col>
            <v-col>
              <v-slider
              label="Specificity"
              v-model="specificityVal"
              step="1"
              :min="minSpecificityVal"
              :max="maxSpecificityVal"
              color="blue"
              thumb-label="always">
              </v-slider>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <ErrorBtn btnIcon="mdi-restart" btnName="Reset" @click.native="resetFilters()" />
            </v-col>
            <v-col align="right">
              <PrimaryBtn btnName="Apply" @click.native="applyFilters()" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <ProjectsTable :filteredProjects="filteredProjects" />
    <DetailedView :selectedProject="$store.state.selectedProject"></DetailedView>
  </v-container>
</template>
<script>
import projects from '../../assets/projects.json';
import cdr_fyi_data from '../../assets/cdr_fyi_data.json';
import verra_data from '../../assets/verra_data.json';
import PrimaryBtn from '../common/PrimaryBtn.vue';
import ErrorBtn from '../common/ErrorBtn.vue';
import ProjectsTable from './components/Table.vue';
import DetailedView from './components/DetailedView.vue';

export default {
  name: 'HomePage',
  components: {
    PrimaryBtn,
    ErrorBtn,
    ProjectsTable,
    DetailedView,
  },
  data() {
    return {
      search: "",
      allTags: [],
      allLocations: [],
      allApplicants: [],
      allSourceNames: [],
      allSourceLicenses: [],
      minMechanismVal: "",
      minVolumeVal: "",
      minNegativityVal: "",
      minPermanenceVal: "",
      minAdditionalityVal: "",
      minPriceVal: "",
      minSpecificityVal: "",
      maxMechanismVal: "",
      maxVolumeVal: "",
      maxNegativityVal: "",
      maxPermanenceVal: "",
      maxAdditionalityVal: "",
      maxPriceVal: "",
      maxSpecificityVal: "",
      mechanismVal: "",
      volumeVal: "",
      negativityVal: "",
      permanenceVal: "",
      additionalityVal: "",
      priceVal: "",
      specificityVal: "",
      tags: [],
      locations: [],
      applicants: [],
      sourceNames: [],
      sourceLicenses: [],
      description: [],
      methods: [],
      projectRating: 0,
      allProjects: [],
      filteredProjects: [],
      projectHeaders: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Applicant',
          value: 'applicant',
        },
        {
          text: 'Tags',
          value: 'tags',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Location',
          value: 'location',
        },
        {
          text: 'Rating',
          value: 'rating',
        },
        {
          text: 'Detailed view',
          value: 'view',
        },
      ],
      selectedProject: {},
    };
  },
  methods: {
    setAllProjects() {
      const URL = "https://carbon-api.zasti.ai/get_all_projects";
      this.axios.post(URL)
      .then((response) => {
        console.log(response)
        this.$store.dispatch('setAllProjects', response.data.projects);
      });
      this.allProjects = structuredClone(this.$store.state.allProjects);
      this.filteredProjects = structuredClone(this.allProjects);
      this.allProjects = structuredClone(projects.projects);
      this.allProjects.push(...structuredClone(cdr_fyi_data));
      this.allProjects.push(...structuredClone(verra_data));
      this.filteredProjects = structuredClone(this.allProjects);
      const allProjects = this.allProjects;
      this.$store.dispatch('setAllProjects', allProjects);
      
      return true;
    },
    getTags() {
      const allProjects = this.allProjects;
      let tags = [];

      for (let project of allProjects) {
        const projectTags = project.tags;

        for (let tag of projectTags) {
          if (!tags.includes(tag)) {
            tags.push(tag);
          }
        }
      }

      tags.sort();
      this.allTags = tags;
      this.$store.dispatch('setAllTags', tags);

      return true;
    },
    getLocations() {
      const allProjects = this.allProjects;
      let locations = [];

      for (let project of allProjects) {
        const projectLocation = project.location.name;
        
        if (!locations.includes(projectLocation)) {
          locations.push(projectLocation);
        }
      }

      locations.sort();
      locations.splice(0, 1);
      this.allLocations = locations;
      this.$store.dispatch('setAllLocations', locations);

      return true;
    },
    getApplicants() {
      const allProjects = this.allProjects;
      let applicants = [];

      for (let project of allProjects) {
        const projectApplicant = project.applicant;

        if (!applicants.includes(projectApplicant)) {
          applicants.push(projectApplicant);
        }
      }

      applicants.sort();
      this.allApplicants = applicants;
      this.$store.dispatch('setAllApplicants', applicants);

      return true;
    },
    getSourceNames() {
      const allProjects = this.allProjects;
      let sourceNames = [];

      for (let project of allProjects) {
        const sourceName = project.source.name;

        if (!sourceNames.includes(sourceName)) {
          sourceNames.push(sourceName);
        }
      }

      sourceNames.sort();
      sourceNames.splice(0, 1);
      this.allSourceNames = sourceNames;
      
      return true;
    },
    getSourceLicenses() {
      const allProjects = this.allProjects;
      let sourceLicenses = [];

      for (let project of allProjects) {
        const sourceLicense = project.source.license;

        if (!sourceLicenses.includes(sourceLicense)) {
          sourceLicenses.push(sourceLicense);
        }
      }

      sourceLicenses.sort();
      this.allSourceLicenses = sourceLicenses;
      
      return true;
    },
    initSelectMenuItems() {
      this.getTags();
      this.getLocations();
      this.getApplicants();
      this.getSourceNames();
      this.getSourceLicenses();
      
      return true;
    },
    getMetricRange(metricIndex) {
      const projects = this.allProjects.filter(project => {
        return Number(project.metrics[metricIndex].value) === project.metrics[metricIndex].value;
      }).map(project => project.metrics[metricIndex].value);
      const minVal = Math.min(...projects);
      const maxVal = Math.max(...projects);
      
      return [minVal, maxVal];
    },
    initMetricsFilterRange() {
      [this.minMechanismVal, this.maxMechanismVal] = this.getMetricRange(0);
      [this.minVolumeVal, this.maxVolumeVal] = this.getMetricRange(1);
      [this.minNegativityVal, this.maxNegativityVal] = this.getMetricRange(2);
      [this.minPermanenceVal, this.maxPermanenceVal] = this.getMetricRange(3);
      [this.minAdditionalityVal, this.maxAdditionalityVal] = this.getMetricRange(4);
      [this.minPriceVal, this.maxPriceVal] = this.getMetricRange(5);
      [this.minSpecificityVal, this.maxSpecificityVal] = this.getMetricRange(6);
      
      return true;
    },
    applyTagsFilter() {
      const tags = this.tags;

      if (tags.length > 0) {
        this.filteredProjects = this.filteredProjects.filter((project) => {
          for (let tag of project.tags) {
            if (tags.includes(tag)) {
              return true;
            }
          }
        });
      }
      
      return true;
    },
    applyLocationsFilter() {
      const locations = this.locations;

      if (locations.length > 0) {
        this.filteredProjects = this.filteredProjects.filter((project) => {
          return locations.includes(project.location.name);
        });
      }
      
      return true;
      
    },
    applyApplicantsFilter() {
      const applicants = this.applicants;

      if (applicants.length > 0) {
        this.filteredProjects = this.filteredProjects.filter((project) => {
          return applicants.includes(project.applicant);
        });
      }

      return true;
    },
    applySourceNamesFilter() {
      const sourceNames = this.sourceNames;

      if (sourceNames.length > 0) {
        this.filteredProjects = this.filteredProjects.filter((project) => {
          return sourceNames.includes(project.source.name);
        });
      }
      
      return true;
    },
    applySourceLicensesFilter() {
      const sourceLicenses = this.sourceLicenses;

      if (sourceLicenses.length > 0) {
        this.filteredProjects = this.filteredProjects.filter((project) => {
          return sourceLicenses.includes(project.source.license);
        });
      }
      
      return true;
    },
    applyDescriptionFilter() {
      if (this.description.length === 0) {
        return true;
      } else {
        const description = this.description;
        
        this.filteredProjects = this.filteredProjects.filter((project) => {
          for (let descriptionEntry of description) {
            if (project.description.toLowerCase().includes(descriptionEntry.toLowerCase())) {
              return true;
            } else {
              continue;
            }
          }

          return false;
        });
      }
      
      return true;
    },
    applyMethodsFilter() {
      if (this.methods.length === 0) {
        return true;
      } else {
        const methods = this.methods;
        
        this.filteredProjects = this.filteredProjects.filter((project) => {
          for (let method of methods) {
            if (project.methods.toLowerCase().includes(method.toLowerCase())) {
              return true;
            } else {
              continue;
            }
          }

          return false;
        });
      }
      
      return true;
    },
    applyRatingsFilter() {
      const rating = this.projectRating;

      if (rating === 0) {
        return true;
      } else {
        this.filteredProjects = this.filteredProjects.filter((project) => {
          return project.rating >= rating;
        });
      }
      
      return true;
    },
    applyMetricsFilter(index, metric, minMetric) {
      if (metric == "" || metric == minMetric) {
        return true;
      } else {
        this.filteredProjects = this.filteredProjects.filter((project) => {
          return project.metrics[index].value >= metric;
        });
      }
      
      return true;
    },
    enableSnackbar(message) {
      const color = "success";
      this.$store.dispatch('setSnackbarProps', { message, color });
      this.$store.dispatch('openSnackbar');

      return true;
    },
    applyFiltersSnackbar() {
      const message = "The filters have been applied successfully!";
      this.enableSnackbar(message);
      
      return true;
    },
    resetFiltersSnackbar() {
      const message = "The filters have been reset successfully!";
      this.enableSnackbar(message);
      
      return true;
    },
    scrollToBottom() {
      setTimeout(() => {
        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
      }, 0);
      
      return true;
    },
    applyFilters() {
      this.applyFiltersSnackbar();
      this.resetTable();
      this.applyTagsFilter();
      this.applyLocationsFilter();
      this.applyApplicantsFilter();
      this.applySourceNamesFilter();
      this.applySourceLicensesFilter();
      this.applyDescriptionFilter();
      this.applyMethodsFilter();
      this.applyRatingsFilter();

      // The different numbers below denote the various metrics.
      this.applyMetricsFilter(0, this.mechanismVal, this.minMechanismVal);
      this.applyMetricsFilter(1, this.volumeVal, this.minVolumeVal);
      this.applyMetricsFilter(2, this.negativityVal, this.minNegativityVal);
      this.applyMetricsFilter(3, this.permanenceVal, this.minPermanenceVal);
      this.applyMetricsFilter(4, this.additionalityVal, this.minAdditionalityVal);
      this.applyMetricsFilter(5, this.priceVal, this.minPriceVal);
      this.applyMetricsFilter(6, this.specificityVal, this.minSpecificityVal);



      // **********************************************************************
      // Uncomment this code segment once ssl 
      // certificate is active on both FE and BE.
      // Remove the above functions when this section is uncommented.
      // 
      // const URL = "http://54.71.89.6:8029/get_filtered_projects";
      // let formData = new FormData();
      // formData.append("tagfilter", this.tags);
      // formData.append("locationFilter", this.locations);
      // formData.append("applicantFilter", this.applicants);
      // formData.append("sourceNameFilter", this.sourceNames);
      // formData.append("sourceLicenseFilter", this.sourceLicenses);
      // formData.append("descriptionFilter", this.description);
      // formData.append("methodsFilter", this.methods);
      // formData.append("ratingFilter", this.projectRating);
      // formData.append("mechanismFilter", this.mechanismVal);
      // formData.append("volumeFilter", this.volumeVal);
      // formData.append("negativityFilter", this.negativityVal);
      // formData.append("permanenceFilter", this.permanenceVal);
      // formData.append("additionalityFilter", this.additionalityVal);
      // formData.append("priceFilter", this.priceVal);
      // formData.append("specificityFilter", this.specificityVal);
      // this.axios.post(URL, formData, { withCredentials: true })
      // .then((response) => {
      //   console.log(response);
      //   this.filteredProjects = response.data.projects;
      //   console.log(this.filteredProjects.length);
      // });
      // 
      // **********************************************************************



      this.scrollToBottom();

      return true; 
    },
    resetTags() {
      this.tags = [];

      return true;
    },
    resetLocations() {
      this.locations = [];

      return true;
    },
    resetApplicants() {
      this.applicants = [];

      return true;
    },
    resetSourceNames() {
      this.sourceNames = [];

      return true;
    },
    resetSourceLicenses() {
      this.sourceLicenses = [];

      return true;
    },
    resetDescription() {
      this.description = [];

      return true;
    },
    resetMethods() {
      this.methods = [];
      
      return true;
    },
    resetRatings() {
      this.projectRating = 0;

      return true;
    },
    resetMetrics() {
      this.mechanismVal = "";
      this.volumeVal = "";
      this.negativityVal = "";
      this.permanenceVal = "";
      this.additionalityVal = "";
      this.priceVal = "";
      this.specificityVal = "";
      
      return true;
    },
    resetTable() {
      this.filteredProjects = this.allProjects;

      return true;
    },
    resetFilters() {
      this.resetFiltersSnackbar();
      this.resetTags();
      this.resetLocations();
      this.resetApplicants();
      this.resetSourceNames();
      this.resetSourceLicenses();
      this.resetDescription();
      this.resetMethods();
      this.resetRatings();
      this.resetMetrics();
      this.resetTable();

      return true;
    },
  },
  beforeMount() {
    this.setAllProjects();
    this.initSelectMenuItems();
    this.initMetricsFilterRange();
  },
}
</script>
