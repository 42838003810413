<template>
  <v-container>

  </v-container>
</template>
<script>
export default {
  name: 'BackgroundImg',
}
</script>
<style lang="scss" scoped>
.container {
  background-image: url("../../../assets/tree.jpg");
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  filter: brightness(60%);
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
}
</style>