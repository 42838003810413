<template>
    <v-container>
        <BackgroundImg />
        <v-card class="pa-6 rounded-xl">
            <v-card-title class="justify-center">Login</v-card-title>
            <v-card-text>
              <v-form class="mt-10">
                  <v-text-field
                  outlined
                  rounded
                  dense
                  label="Username"
                  type="text"
                  v-model="username"
                  @keyup.enter="login()"
                  required>
                  </v-text-field>
                  <v-text-field
                  outlined
                  rounded
                  dense
                  label="Password"
                  type="password"
                  v-model="password"
                  @keyup.enter="login()"
                  required>
                  </v-text-field>
                  <v-row class="mt-6">
                      <v-col class="d-flex justify-end">
                          <ErrorBtn btnIcon="mdi-restart" btnName="Reset" @click.native="reset()" />
                      </v-col>
                      <v-col>
                          <PrimaryBtn btnName="Login" :loading="loginBtnLoader" @click.native="login()" />
                      </v-col>
                  </v-row>
              </v-form>
            </v-card-text>
        </v-card>
        <PoweredByZasti />
    </v-container>
</template>
<script>
import ErrorBtn from '../common/ErrorBtn.vue';
import PrimaryBtn from '../common/PrimaryBtn.vue';
import BackgroundImg from './components/BackgroundImg.vue';
import PoweredByZasti from './components/PoweredByZasti.vue';

export default {
    name: 'LoginPage',
    components: {
        ErrorBtn,
        PrimaryBtn,
        BackgroundImg,
        PoweredByZasti,
    },
    data() {
        return {
            username: "",
            password: "",
            loginBtnLoader: false,
        };
    },
    methods: {
        setSnackbarProps(message, color) {
            this.snackbarMessage = message;
            this.snackbarColor = color;
            this.snackbar = true;

            return true;
        },
        submitForm() {
            const url = "https://carbon-api.zasti.ai/auth";
            let formData = new FormData();
            formData.append("username", this.username);
            formData.append("password", this.password);

            return this.axios.post(url, formData)
            .then((response) => {
              console.log("Login response");
              console.log(response);
                return response.data.auth;
            });
        },
        loginSnackbar(isAuth) {
            let message;
            let color;

            if (isAuth) {
                message = "You have been logged in successfully!";
                color = "success";
            } else {
                message = "The credentials are incorrect.";
                color = "error";
            }
            
            this.$store.dispatch('setSnackbarProps', { message, color });
            this.$store.dispatch('openSnackbar');
            
            return true;
        },
        async login() {
            this.loginBtnLoader = true;
            const isAuth = await this.submitForm();
            this.loginSnackbar(isAuth);
            
            if (isAuth) {
                this.$store.dispatch('authenticateUser');
                setTimeout(() => {
                    this.$router.push("/");
                }, 50);
            }

            this.loginBtnLoader = false;

            return true;
        },
        resetInputs() {
            this.username = "";
            this.password = "";

            return true;
        },
        resetSnackbar() {
            const message = "The form has been reset successfully!";
            const color = "success";
            this.$store.dispatch('setSnackbarProps', { message, color });
            this.$store.dispatch('openSnackbar');

            return true;
        },
        reset() {
            this.resetInputs();
            this.resetSnackbar();

            return true;
        },
    },
}
</script>
<style scoped>
.v-card {
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
</style>