import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    allProjects: [],
    allTags: [],
    allLocations: [],
    loader: true,
    snackbar: false,
    snackbarMessage: "",
    snackbarColor: "",
    drawer: false,
    isAuthenticated: false,
    selectedProject: {},
    detailedView: false,
  },
  mutations: {
    AUTHENTICATE_USER(state) {
      state.isAuthenticated = true;
    },
    LOGOUT(state) {
      state.isAuthenticated = false;
    },
    SET_ALL_PROJECTS(state, allProjects) {
      state.allProjects = allProjects;
    },
    SET_ALL_TAGS(state, allTags) {
      state.allTags = allTags;
    },
    SET_ALL_LOCATIONS(state, allLocations) {
      state.allLocations = allLocations;
    },
    SET_ALL_APPLICANTS(state, allApplicants) {
      state.allApplicants = allApplicants;
    },
    SET_SELECTED_PROJECT(state, project) {
      state.selectedProject = project;
    },
    OPEN_DETAILED_VIEW(state) {
      state.detailedView = true;
    },
    CLOSE_DETAILED_VIEW(state) {
      state.detailedView = false;
    },
    START_LOADING(state) {
      state.loader = true;
    },
    END_LOADING(state) {
      state.loader = false;
    },
    SET_SNACKBAR_PROPS(state, { message, color }) {
      state.snackbarMessage = message;
      state.snackbarColor = color;
    },
    OPEN_SNACKBAR(state) {
      state.snackbar = true;
    },
    CLOSE_SNACKBAR(state) {
      state.snackbar = false;
    },
    SET_SIDE_NAV_MENU(state) {
      state.drawer = !state.drawer;
    },
  },
  actions: {
    authenticateUser(context) {
      context.commit("AUTHENTICATE_USER");

      return true;
    },
    logout(context) {
      context.commit("LOGOUT");
      context.dispatch('startLoading');
      setTimeout(() => {
        router.push("/auth");
      }, 500);
    },
    setAllProjects(context, allProjects) {
      context.commit("SET_ALL_PROJECTS", allProjects);
      
      return true;
    },
    setAllTags(context, allTags) {
      context.commit("SET_ALL_TAGS", allTags);
      
      return true;
    },
    setAllLocations(context, allLocations) {
      context.commit('SET_ALL_LOCATIONS', allLocations);
      
      return true;
    },
    setAllApplicants(context, allApplicants) {
      context.commit('SET_ALL_APPLICANTS', allApplicants);
    },
    setSelectedProject(context, project) {
      context.commit("SET_SELECTED_PROJECT", project);
      
      return true;
    },
    openDetailedView(context) {
      context.commit("OPEN_DETAILED_VIEW");
      
      return true;
    },
    closeDetailedView(context) {
      context.commit("CLOSE_DETAILED_VIEW");
      
      return true;
    },
    startLoading(context) {
      context.commit("START_LOADING");

      return true;
    },
    endLoading(context) {
      setTimeout(() => {
        context.commit("END_LOADING");
      }, 1500);

      return true;
    },
    setSnackbarProps(context, { message, color }) {
      context.commit('SET_SNACKBAR_PROPS', { message, color });

      return true;
    },
    openSnackbar(context) {
      context.commit("OPEN_SNACKBAR");
      
      return true;
    },
    closeSnackbar(context) {
      context.commit("CLOSE_SNACKBAR");
      
      return true;
    },
    setSideNavMenu(context) {
      context.commit("SET_SIDE_NAV_MENU");
      
      return true;
    },
  },
  modules: {
  }
})
