<template>
    <v-container>
        <v-img src="../../../../public/ZASTI logo_Main Logo Slogan.png"
        max-height="150"
        max-width="150"></v-img>
    </v-container>
</template>
<script>
export default {
    name: 'PoweredByZasti',
};
</script>
<style scoped>
.container {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 200px;
}
</style>