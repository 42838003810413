<template>
    <v-container>
        <h1 class="blue-grey--text text--darken-1 text-center mb-6">Summary</h1>
        <ProjectSummary />
        <MetricBarChart />
        <MetricsByCategory />
        <LocationBarChart />
    </v-container>
</template>
<script>
import ProjectSummary from './components/ProjectSummary.vue';
import MetricBarChart from './components/MetricBarChart.vue';
import MetricsByCategory from './components/MetricsByCategory.vue';
import LocationBarChart from './components/LocationBarChart.vue';
// import { Bar } from 'vue-chartjs'
// import { Pie } from 'vue-chartjs'
// import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

// ChartJS.register(ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'ProjectsSummary',
    components: {
      ProjectSummary,
      MetricBarChart,
      MetricsByCategory,
      LocationBarChart,
        // Bar,
        // Pie,
    },
    data() {
        return {
            carbonSequestrationByCategory: "",
            totalPriceForCategory: "",
            averagePriceForCategory: "",
            averagePermanenceForCategory: "",
            averageNegativityForCategory: "",
            metric: "Volume",
            category: "forests",
            categories: [],
            metrics: [
                "Volume",
                "Negativity",
                "Permanence",
                "Price",
            ],
            barChartByCategoryItem: "Projects by category",
            barChartByCategoryItems: [
                "Projects by category",
                "Projects by rating",
                "Carbon sequestration by category (total tCO2e)",
                "Average carbon sequestration per project by category (tCO2e)",
                "Total price by category ($)",
                "Average price by category ($/tCO2e)",
                "Average permanence by category (years)",
                "Average negativity by category",
                "Top 10 applicants by volume",
            ],
            barChartValuesByCategory: [],
            chartOptions: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            autoSkip: false,
                        }
                    }
                },
                responsive: true,
            },
            pieChartOptions: {
                responsive: false,
            },
            noOfProjectsPerTag: {},
            topValuesTypes: [
              "Top 5",
              "Top 10",
              "Top 15",
              "Top 20",
              "Top 25",
            ],
            categoryTopNo: "Top 10",
            locationTopNo: "Top 10",
        };
    },
    methods: {
        setAllProjects() {
            this.allProjects = this.$store.state.allProjects;

            return true;
        },
        getTopTenByValue(barChartItems) {
          let values = Object.values(barChartItems);
          values = values.sort((a, b) => {
              return b - a;
          });
          const tenthHighestVolume = values[9];
          let topTenByValue = {};

          for (let itemKey in barChartItems) {
              if (barChartItems[itemKey] >= tenthHighestVolume) {
                  topTenByValue[itemKey] = barChartItems[itemKey];
              }
          }

          return topTenByValue;
        },
        calcTotalProjects() {
            const noOfTotalProjects = this.allProjects.length;
            this.noOfTotalProjects = noOfTotalProjects;

            return true;
        },
        calcCarbonRemovalProjects() {
            const carbonRemovalProjects = this.allProjects.filter((project) => {
                return project.metrics[0].value == 1
            });
            const noOfCarbonRemovalProjects = carbonRemovalProjects.length;
            this.noOfCarbonRemovalProjects = noOfCarbonRemovalProjects;

            return true;
        },
        calcCarbonAvoidanceProjects() {
            const carbonAvoidanceProjects = this.allProjects.filter((project) => {
                return project.metrics[0].value == 2
            });
            const noOfCarbonAvoidanceProjects = carbonAvoidanceProjects.length;
            this.noOfCarbonAvoidanceProjects = noOfCarbonAvoidanceProjects;

            return true;
        },
        sortGraphValuesInDescOrder(labels, data) {
            let bars = labels.map((label, index) => {
                return {
                    label: label,
                    data: data[index]
                };
            });
            let sortedBars = bars.sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                } else if (a.label > b.label) {
                    return 1;
                } else {
                    return 0;
                }
            });
            let sortedLabels = [];
            let sortedData = [];
            sortedBars.forEach((bar) => {
                sortedLabels.push(bar.label);
                sortedData.push(bar.data);
            });

            return [sortedLabels, sortedData];
        },
        calcProjectsPerTag() {
            const allTags = this.$store.state.allTags;
            let noOfProjectsPerTag = {};
            
            for (let tag of allTags) {
                const projects = this.allProjects.filter((project) => {
                    return project.tags.includes(tag);
                });
                const noOfProjects = projects.length;
                noOfProjectsPerTag[tag] = noOfProjects;
            }

            this.$store.state.noOfProjectsPerTag = noOfProjectsPerTag;

            let topTenProjects = this.getTopTenByValue(noOfProjectsPerTag);

            let labels = Object.keys(topTenProjects);
            const chartLabel = "No of projects per tag";
            const backgroundColor = "#00acee";
            let data = Object.values(topTenProjects);
            [labels, data] = this.sortGraphValuesInDescOrder(labels, data);
            this.noOfProjectsPerTag = {
                labels: labels,
                datasets: [
                    {
                        label: chartLabel,
                        backgroundColor: backgroundColor,
                        data: data,
                        borderRadius: 15,
                    }
                ]
            };

            return true;
        },
        calcProjectsPerRating() {
            let noOfProjectsPerRating = {
                "1": "",
                "2": "",
                "3": "",
                "4": "",
                "5": "",
            }
            const ratings = Object.keys(noOfProjectsPerRating);

            for (let rating of ratings) {
                const projects = this.allProjects.filter((project) => {
                    return project.rating === rating;
                });
                const noOfProjects = projects.length;
                noOfProjectsPerRating[rating] = noOfProjects;
            }

            const labels = Object.keys(noOfProjectsPerRating);
            const chartLabel = "No of projects by rating";
            const backgroundColor = [
                "#cda1ff",
                "#b778ff",
                "#a759ff",
                "#9436ff",
                "#8112ff",
            ];
            const data = Object.values(noOfProjectsPerRating);
            this.noOfProjectsPerRating = {
                labels: labels,
                datasets: [
                    {
                        label: chartLabel,
                        backgroundColor: backgroundColor,
                        data: data
                    }
                ]
            };

            return true;
        },
        calcTotalVolumePerTag() {
            const allTags = this.$store.state.allTags;
            let totalVolumePerTag = {};

            for (let tag of allTags) {
                const projects = this.allProjects.filter((project) => {
                    return project.tags.includes(tag);
                });
                const totalVolume = projects.reduce((total, project) => {
                    const volume = project.metrics[1].value;
                    
                    if (!isNaN(volume)) {
                        return total + volume;
                    } else {
                        return total;
                    }
                }, 0);

                if (totalVolume > 0) {
                    totalVolumePerTag[tag] = totalVolume;
                }
            }

            this.$store.state.totalVolumePerTag = totalVolumePerTag;

            let topTenTotalVolume = this.getTopTenByValue(totalVolumePerTag);

            let labels = Object.keys(topTenTotalVolume);
            const chartLabel = "Total volume per tag (tCO2e)";
            const backgroundColor = "#FF5733";
            let data = Object.values(topTenTotalVolume);
            [labels, data] = this.sortGraphValuesInDescOrder(labels, data);
            this.totalVolumePerTag = {
                labels: labels,
                datasets: [
                    {
                        label: chartLabel,
                        backgroundColor: backgroundColor,
                        data: data,
                        borderRadius: 15,
                    }
                ]
            };

            return true;
        },
        calcAverageVolumePerTag() {
            const allTags = this.$store.state.allTags;
            let averageVolumePerTag = {};

            for (let tag of allTags) {
                let noOfProjects = 0;
                const projects = this.allProjects.filter((project) => {
                    return project.tags.includes(tag);
                });
                const totalVolume = projects.reduce((total, project) => {
                    const volume = project.metrics[1].value;
                    
                    if (!isNaN(volume)) {
                        noOfProjects += 1;
                        return total + volume;
                    } else {
                        return total;
                    }
                }, 0);

                if (totalVolume > 0) {
                    const averageVolume = totalVolume / noOfProjects;
                    averageVolumePerTag[tag] = averageVolume;
                }
            }

            this.$store.state.averageVolumePerTag = averageVolumePerTag;

            let topTenVolume = this.getTopTenByValue(averageVolumePerTag);

            let labels = Object.keys(topTenVolume);
            const chartLabel = "Average volume per project by category (tCO2e)";
            const backgroundColor = "#FF5733";
            let data = Object.values(topTenVolume);
            [labels, data] = this.sortGraphValuesInDescOrder(labels, data);
            this.averageVolumePerTag = {
                labels: labels,
                datasets: [
                    {
                        label: chartLabel,
                        backgroundColor: backgroundColor,
                        data: data,
                        borderRadius: 15,
                    }
                ]
            };

            return true;
        },
        calcTotalPricePerTag() {
            const allTags = this.$store.state.allTags;
            let totalPricePerTag = {};

            for (let tag of allTags) {
                const projects = this.allProjects.filter((project) => {
                    return project.tags.includes(tag);
                });
                const totalPrice = projects.reduce((total, project) => {
                    const price = project.metrics[5].value * project.metrics[1].value;

                    if (!isNaN(price)) {
                        return total + price;
                    } else {
                        return total;
                    }
                }, 0);

                if (totalPrice > 0) {
                    totalPricePerTag[tag] = totalPrice;
                }
            }

            this.$store.state.totalPricePerTag = totalPricePerTag;

            let topTenTotalPrice = this.getTopTenByValue(totalPricePerTag);

            let labels = Object.keys(topTenTotalPrice);
            const chartLabel = "Total price per tag ($)";
            const backgroundColor = "#0BDA00";
            let data = Object.values(topTenTotalPrice);
            [labels, data] = this.sortGraphValuesInDescOrder(labels, data);
            this.totalPricePerTag = {
                labels: labels,
                datasets: [
                    {
                        label: chartLabel,
                        backgroundColor: backgroundColor,
                        data: data,
                        borderRadius: 15,
                    }
                ]
            };

            return true;
        },
        calcAveragePricePerTag() {
            const allTags = this.$store.state.allTags;
            let averagePricePerTag = {};

            for (let tag of allTags) {
                const projects = this.allProjects.filter((project) => {
                    return project.tags.includes(tag);
                });
                let noOfProjectsForCurrentTag = 0;
                const totalPrice = projects.reduce((total, project) => {
                    const price = project.metrics[5].value;

                    if (!isNaN(price)) {
                        noOfProjectsForCurrentTag += 1
                        
                        return total + price;
                    } else {
                        return total;
                    }
                }, 0);

                if (totalPrice > 0) {
                    averagePricePerTag[tag] = totalPrice / noOfProjectsForCurrentTag;
                }
            }

            this.$store.state.averagePricePerTag = averagePricePerTag;

            let topTenAveragePrice = this.getTopTenByValue(averagePricePerTag);

            let labels = Object.keys(topTenAveragePrice);
            const chartLabel = "Average price per tag ($)";
            const backgroundColor = "#00E3E4";
            let data = Object.values(topTenAveragePrice);
            [labels, data] = this.sortGraphValuesInDescOrder(labels, data);
            this.averagePricePerTag = {
                labels: labels,
                datasets: [
                    {
                        label: chartLabel,
                        backgroundColor: backgroundColor,
                        data: data,
                        borderRadius: 15,
                    }
                ]
            };

            return true;
        },
        calcAveragePermanencePerTag() {
            const allTags = this.$store.state.allTags;
            let totalPermanencePerTag = {};

            for (let tag of allTags) {
                const projects = this.allProjects.filter((project) => {
                    return project.tags.includes(tag);
                });
                let noOfProjects = 0;
                const totalPermanence = projects.reduce((total, project) => {
                    const permanence = project.metrics[3].value;

                    if (!isNaN(permanence)) {
                        noOfProjects += 1;
                        return total + permanence;
                    } else {
                        return total;
                    }
                }, 0);

                if (totalPermanence > 0) {
                    totalPermanencePerTag[tag] = totalPermanence / noOfProjects;
                }
            }

            this.$store.state.totalPermanencePerTag = totalPermanencePerTag;

            let topTenPermanence = this.getTopTenByValue(totalPermanencePerTag);

            let labels = Object.keys(topTenPermanence);
            const chartLabel = "Average permanence per tag (years)";
            const backgroundColor = "#C1E100";
            let data = Object.values(topTenPermanence);
            [labels, data] = this.sortGraphValuesInDescOrder(labels, data);
            this.totalPermanencePerTag = {
                labels: labels,
                datasets: [
                    {
                        label: chartLabel,
                        backgroundColor: backgroundColor,
                        data: data,
                        borderRadius: 15,
                    }
                ]
            };
            
            return true;
        },
        calcAverageNegativityPerTag() {
            const allTags = this.$store.state.allTags;
            let totalNegativityPerTag = {};

            for (let tag of allTags) {
                const projects = this.allProjects.filter((project) => {
                    return project.tags.includes(tag);
                });
                let noOfProjects = 0;
                const totalNegativity = projects.reduce((total, project) => {
                    const negativity = project.metrics[2].value;
                    
                    if (!isNaN(negativity)) {
                        noOfProjects += 1;
                        return total + negativity;
                    } else {
                        return total;
                    }
                }, 0);

                if (totalNegativity > 0) {
                    totalNegativityPerTag[tag] = totalNegativity / noOfProjects;
                }
            }

            this.$store.state.totalNegativityPerTag = totalNegativityPerTag;

            let topTenNegativities = this.getTopTenByValue(totalNegativityPerTag);

            let labels = Object.keys(topTenNegativities);
            const chartLabel = "Average negativity per tag";
            const backgroundColor = "#7B00E1";
            let data = Object.values(topTenNegativities);
            [labels, data] = this.sortGraphValuesInDescOrder(labels, data);
            this.totalNegativityPerTag = {
                labels: labels,
                datasets: [
                    {
                        label: chartLabel,
                        backgroundColor: backgroundColor,
                        data: data,
                        borderRadius: 15,
                    }
                ]
            };

            return true;
        },
        calcTopTenApplicantsByVolume() {
            const allApplicants = this.$store.state.allApplicants;
            let applicantsByVolume = {};
            
            for (let applicant of allApplicants) {
                const projects = this.$store.state.allProjects.filter((project) => {
                    return project.applicant === applicant;
                });
                const totalVolume = projects.reduce((total, project)=> {
                    const volume = project.metrics[1].value;
                    
                    if (!isNaN(volume)) {
                        return total + volume;
                    } else {
                        return total;
                    }
                }, 0);
                applicantsByVolume[applicant] = totalVolume;
            }

            let labels = Object.keys(applicantsByVolume);
            const chartLabel = "Top 10 applicants by volume";
            this.chartLabel = chartLabel;
            const backgroundColor = "#444";
            this.backgroundColor = backgroundColor;
            let data = Object.values(applicantsByVolume);
            applicantsByVolume = {
                labels: labels,
                datasets: [
                    {
                        label: chartLabel,
                        backgroundColor: backgroundColor,
                        data: data,
                        borderRadius: 15,
                    }
                ]
            };

            return applicantsByVolume;
        },
        calcBarChartValuesByCategory() {
            const metric = this.barChartByCategoryItem;
            const projectsByCategory = "Projects by category";
            const projectsByRating = "Projects by rating";
            const carbonSequestrationByCategory = "Carbon sequestration by category (total tCO2e)";
            const averageCarbonSequestrationByCategory = "Average carbon sequestration per project by category (tCO2e)";
            const totalPriceByCategory = "Total price by category ($)";
            const averagePriceByCategory = "Average price by category ($/tCO2e)";
            const totalPermanenceByCategory = "Average permanence by category (years)";
            const totalNegativityByCategory = "Average negativity by category";
            const topTenApplicantsByVolume = "Top 10 applicants by volume";
            this.showPieChart = false;
            
            if (metric === projectsByCategory) {
                this.barChartValuesByCategory = this.noOfProjectsPerTag;
            } else if (metric === projectsByRating) {
                this.barChartValuesByCategory = this.noOfProjectsPerRating;
                this.showPieChart = true;
            } else if (metric === carbonSequestrationByCategory) {
                this.barChartValuesByCategory = this.totalVolumePerTag;
            } else if (metric === averageCarbonSequestrationByCategory) {
                this.barChartValuesByCategory = this.averageVolumePerTag;
            } else if (metric === totalPriceByCategory) {
                this.barChartValuesByCategory = this.totalPricePerTag;
            } else if (metric === averagePriceByCategory) {
                this.barChartValuesByCategory = this.averagePricePerTag;
            } else if (metric === totalPermanenceByCategory) {
                this.barChartValuesByCategory = this.totalPermanencePerTag;
            } else if (metric === totalNegativityByCategory) {
                this.barChartValuesByCategory = this.totalNegativityPerTag;
            } else if (metric === topTenApplicantsByVolume) {
                this.barChartValuesByCategory = this.topTenApplicantsByVolume;
            }

            return true;
        },
        calcMetricByLocation() {
            const metric = this.metric.toLowerCase();
            const allLocations = this.$store.state.allLocations;
            let metricValuesPerLocation = {};
            let index;
            let unit;

            if (metric === "volume") {
                this.locationTileTitle = "Carbon sequestration by location (total tCO2e)";
                index = 1;
                unit = " (tCO2e)"
            } else if (metric === "negativity") {
                this.locationTileTitle = "Average negativity by location";
                index = 2;
                unit = "";
            } else if (metric === "permanence") {
                this.locationTileTitle = "Average permanence by location (years)"
                index = 3;
                unit = " (years)";
            } else {
                this.locationTileTitle = "Total price by location ($)";
                index = 5;
                unit = " ($)";
            }

            for (let location of allLocations) {
                const projects = this.allProjects.filter((project) => {
                    return project.location.name == location;
                });
                let noOfProjects = 0;
                const totalMetricValue = projects.reduce((total, project) => {
                    if (index == 5) {
                        const totalPriceForCurrentProject = project.metrics[index].value * project.metrics[1].value;
                        
                        if (!isNaN(totalPriceForCurrentProject)) {
                            return total + totalPriceForCurrentProject;
                        } else {
                            return total;
                        }
                    } else {
                        if (!isNaN(project.metrics[index].value)) {
                            noOfProjects += 1;
                            return total + project.metrics[index].value;
                        } else {
                            return total;
                        }
                    }
                }, 0);
                if (totalMetricValue > 0) {
                    if (index === 2 || index === 3) {
                        metricValuesPerLocation[location] = totalMetricValue / noOfProjects;
                    } else {
                        metricValuesPerLocation[location] = totalMetricValue;
                    }
                } 
            }

            let topTenPerLocation = this.getTopTenByValue(metricValuesPerLocation);

            let labels = Object.keys(topTenPerLocation);
            let chartLabel = this.metric + " by location" + unit;
            const backgroundColor = "#245aaa";
            let data = Object.values(topTenPerLocation);
            [labels, data] = this.sortGraphValuesInDescOrder(labels, data);
            this.metricValuesPerLocation = {
                labels: labels,
                datasets: [
                    {
                        label: chartLabel,
                        backgroundColor: backgroundColor,
                        data: data,
                        borderRadius: 15,
                    }
                ]
            };

            return true;
        },
        calcMetricsByCategory() {
            const category = this.category;
            // const noOfProjectsIndex = this.noOfProjectsPerTag.labels.indexOf(category);
            // this.noOfProjectsForCategory = this.noOfProjectsPerTag.datasets[0].data[noOfProjectsIndex] || "-";
            this.noOfProjectsForCategory = this.$store.state.noOfProjectsPerTag[category];

            // const volumeIndex = this.totalVolumePerTag.labels.indexOf(category);
            // this.carbonSequestrationForCategory = this.totalVolumePerTag.datasets[0].data[volumeIndex] || "-";
            this.carbonSequestrationForCategory = this.$store.state.totalVolumePerTag[category] || "-";

            if (this.carbonSequestrationForCategory !== "-") {
                this.carbonSequestrationForCategory = this.carbonSequestrationForCategory.toLocaleString('en-US', {maximumFractionDigits:2})
            }

            // const totalPriceIndex = this.totalPricePerTag.labels.indexOf(category);
            // this.totalPriceForCategory = this.totalPricePerTag.datasets[0].data[totalPriceIndex] || "-";
            this.totalPriceForCategory = this.$store.state.totalPricePerTag[category] || "-";

            if (this.totalPriceForCategory !== "-") {
                this.totalPriceForCategory = this.totalPriceForCategory.toLocaleString('en-US', {maximumFractionDigits:2})
            }

            // const averagePriceIndex = this.averagePricePerTag.labels.indexOf(category);
            // this.averagePriceForCategory = this.averagePricePerTag.datasets[0].data[averagePriceIndex] || "-";
            this.averagePriceForCategory = this.$store.state.averagePricePerTag[category] || "-";
            
            if (this.averagePriceForCategory !== "-") {
                this.averagePriceForCategory = this.averagePriceForCategory.toLocaleString('en-US', {maximumFractionDigits:2})
            }

            // const averagePermanenceIndex = this.totalPermanencePerTag.labels.indexOf(category);
            // this.averagePermanenceForCategory = this.totalPermanencePerTag.datasets[0].data[averagePermanenceIndex] || "-";
            this.averagePermanenceForCategory = this.$store.state.totalPermanencePerTag[category] || "-";
            
            if (this.averagePermanenceForCategory !== "-") {
                this.averagePermanenceForCategory = this.averagePermanenceForCategory.toLocaleString('en-US', {maximumFractionDigits:2})
            }

            // const averageNegativityIndex = this.totalNegativityPerTag.labels.indexOf(category);
            // this.averageNegativityForCategory = this.totalNegativityPerTag.datasets[0].data[averageNegativityIndex] || "-";
            this.averageNegativityForCategory = this.$store.state.totalNegativityPerTag[category] || "-";

            if (this.averageNegativityForCategory !== "-") {
                this.averageNegativityForCategory = this.averageNegativityForCategory.toLocaleString('en-US', {maximumFractionDigits:2})
            }

            return true;
        },
    },
    beforeMount() {
        // this.setAllProjects();
        // this.calcTotalProjects();
        // this.calcCarbonRemovalProjects();
        // this.calcCarbonAvoidanceProjects();
        // this.calcProjectsPerTag(); //
        // this.calcProjectsPerRating();
        // this.calcTotalVolumePerTag(); //
        // this.calcAverageVolumePerTag(); //
        // this.calcTotalPricePerTag(); //
        // this.calcAveragePricePerTag(); //
        // this.calcAveragePermanencePerTag();
        // this.calcAverageNegativityPerTag();
        // this.calcTopTenApplicantsByVolume(); // modify this function as per the latest requirements. Remove the top 10 functionality. It should be applied only when the user selects an option in the second select menu.
        // this.calcMetricByLocation();
        // this.calcBarChartValuesByCategory();
        // this.categories = this.$store.state.allTags;
        // this.calcMetricsByCategory();
    },
}
</script>