<template>
    <v-container>
        <v-text-field
        v-model="search"
        label="Search by keywords"
        outlined
        rounded
        background-color="#fff">
        </v-text-field>
        <v-data-table
        class="rounded-xl elevation-4"
        :headers="projectHeaders"
        :items="filteredProjects"
        :items-per-page="5"
        :search="search">
            <template v-slot:[`item.tags`]="{ item }">
                <v-chip
                v-for="tag of item.tags"
                :key="tag"
                class="ma-1"
                color="green white--text lighten-1"
                >
                {{ tag }}
                </v-chip>
            </template>
            <template v-slot:[`item.rating`]="{ item }">
                <v-rating
                color="blue lighten-1"
                readonly
                dense
                length="5"
                size="18"
                :value="item.rating">
                </v-rating>
            </template>  
            <template v-slot:[`item.view`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon 
                        v-bind="attrs"
                        v-on="on"
                        @click="viewItem(item)" color="grey">mdi-open-in-new</v-icon>
                    </template>
                    <span>View full details</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </v-container>
</template>
<script>
export default {
    name: 'ProjectsTable',
    props: {
        filteredProjects: {
            type: Array,
        }
    },
    data() {
        return {
            search: "",
            projectHeaders: [
                {
                    text: 'ID',
                    value: 'id',
                },
                {
                    text: 'Applicant',
                    value: 'applicant',
                },
                {
                    text: 'Tags',
                    value: 'tags',
                },
                {
                    text: 'Description',
                    value: 'description',
                },
                {
                    text: 'Location',
                    value: 'location.name',
                },
                {
                    text: 'Rating',
                    value: 'rating',
                },
                {
                    text: 'Details',
                    value: 'view',
                    sortable: false,
                },
            ],
        };
    },
    methods: {
        viewItem(item) {
            let selectedProject = item;
            this.$store.dispatch("setSelectedProject", selectedProject);
            this.$store.dispatch('openDetailedView');
            
            return true;
        },
    },
}
</script>