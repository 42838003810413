<template>
  <v-row>
    <v-col v-for="item of items" :key="item.id">
      <v-card class="rounded-xl text-center">
        <v-card-title class="justify-center blue--text">{{ item.title }}</v-card-title>
        <v-card-text class="font-weight-bold text-h5">{{ item.noOfProjects }}</v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'ProjectSummary',
  data() {
    return {
      items: [
        {
          id: 0,
          title: "Total projects",
          noOfProjects: 0,
        },
        {
          id: 1,
          title: "Carbon removal projects",
          noOfProjects: 0,
        },
        {
          id: 2,
          title: "Carbon avoidance projects",
          noOfProjects: 0,
        },
      ],
    };
  },
  methods: {
    calcTotalProjects() {
      const noOfTotalProjects = this.$store.state.allProjects.length;
      this.items[0].noOfProjects = noOfTotalProjects;

      return true;
    },
    calcCarbonRemovalProjects() {
      const carbonRemovalProjects = this.$store.state.allProjects.filter((project) => {
          return project.metrics[0].value == 1
      });
      const noOfCarbonRemovalProjects = carbonRemovalProjects.length;
      this.items[1].noOfProjects = noOfCarbonRemovalProjects;

      return true;
    },
    calcCarbonAvoidanceProjects() {
      const carbonAvoidanceProjects = this.$store.state.allProjects.filter((project) => {
          return project.metrics[0].value == 2
      });
      const noOfCarbonAvoidanceProjects = carbonAvoidanceProjects.length;
      this.items[2].noOfProjects = noOfCarbonAvoidanceProjects;

      return true;
    },
  },
  beforeMount() {
    this.calcTotalProjects();
    this.calcCarbonRemovalProjects();
    this.calcCarbonAvoidanceProjects();
  },
}
</script>