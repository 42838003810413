<template>
    <v-app-bar
    app
    dense
    color="#002a53"
    dark
    v-if="!isLoginPage()"
    >
        <v-icon @click="openMenu()">mdi-menu</v-icon>
        <v-img src="../../../../public/ZASTI logo_Main Logo Slogan.png"
        class="ml-4"
        max-height="60"
        max-width="60"></v-img>
        <v-app-bar-title class="ml-2 text-h6 blue-grey--text text--lighten-5">Carbon Database</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-btn class="text-none" text @click="logout"><v-icon left small>mdi-logout</v-icon>Logout</v-btn>
    </v-app-bar>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    name: 'AppBar',
    methods: {
      ...mapActions([
        'logout',
      ]),
        openMenu() {
            this.$store.dispatch('setSideNavMenu');

            return true;
        },
        isLoginPage() {
            let isLoginRoute = false;
            const loginPageRouteName = "login";
            const currentRouteName = this.$route.name;

            if (currentRouteName === loginPageRouteName) {
                isLoginRoute = true;
            }

            return isLoginRoute;
        },
    },
}
</script>