<template>
  <v-row class="mb-4">
    <v-col>
      <v-card class="pa-4 rounded-xl">
        <v-card-title class="justify-center blue--text">{{ locationTileTitle }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="9">
                <v-select
                  outlined
                  class="rounded-lg"
                  v-model="metric"
                  :items="metrics"
                  @change="calcMetricByLocation()"
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  outlined
                  class="rounded-lg"
                  v-model="locationTopNo"
                  :items="topValuesTypes"
                  @change="calcMetricByLocation()"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
          <Bar :options="chartOptions" :data="metricValuesPerLocation" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
ChartJS.register(
  ArcElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "LocationBarChart",
  components: {
    Bar,
  },
  data() {
    return {
      chartOptions: {
          plugins: {
              legend: {
                  display: false,
              }
          },
          scales: {
              x: {
                  ticks: {
                      autoSkip: false,
                  }
              }
          },
          responsive: true,
      },
      topValuesTypes: [
        "Top 5",
        "Top 10",
        "Top 15",
        "Top 20",
        "Top 25",
      ],
      locationTopNo: "Top 10",
      metric: "Volume",
      metrics: [
        "Volume",
        "Negativity",
        "Permanence",
        "Price",
      ],
      allMetricValuesPerLocation: {},
      metricValuesPerLocation: {},
    };
  },
  methods: {
    getTopNByValue() {
      let barChartItems = this.allMetricValuesPerLocation;
      let values = Object.values(barChartItems);
      values = values.sort((a, b) => {
          return b - a;
      });
      let n = parseInt(this.locationTopNo.split(" ")[1]); 
      const nthHighestVolume = values[n - 1];
      let topNByValue = {};

      for (let itemKey in barChartItems) {
          if (barChartItems[itemKey] >= nthHighestVolume) {
              topNByValue[itemKey] = barChartItems[itemKey];
          }
      }

      return topNByValue;
    },
    calcMetricByLocation() {
      const metric = this.metric.toLowerCase();
      const allLocations = this.$store.state.allLocations;
      let metricValuesPerLocation = {};
      let index;
      let unit;

      if (metric === "volume") {
        this.locationTileTitle = "Carbon sequestration by location (total tCO2e)";
        index = 1;
        unit = " (tCO2e)";
      } else if (metric === "negativity") {
        this.locationTileTitle = "Average negativity by location";
        index = 2;
        unit = "";
      } else if (metric === "permanence") {
        this.locationTileTitle = "Average permanence by location (years)";
        index = 3;
        unit = " (years)";
      } else {
        this.locationTileTitle = "Total price by location ($)";
        index = 5;
        unit = " ($)";
      }

      for (let location of allLocations) {
        const projects = this.$store.state.allProjects.filter((project) => {
          return project.location.name == location;
        });
        let noOfProjects = 0;
        const totalMetricValue = projects.reduce((total, project) => {
          if (index == 5) {
            const totalPriceForCurrentProject = project.metrics[index].value * project.metrics[1].value;

            if (!isNaN(totalPriceForCurrentProject)) {
              return total + totalPriceForCurrentProject;
            } else {
              return total;
            }
          } else {
            if (!isNaN(project.metrics[index].value)) {
              noOfProjects += 1;
              return total + project.metrics[index].value;
            } else {
              return total;
            }
          }
        }, 0);
        if (totalMetricValue > 0) {
          if (index === 2 || index === 3) {
            metricValuesPerLocation[location] = totalMetricValue / noOfProjects;
          } else {
            metricValuesPerLocation[location] = totalMetricValue;
          }
        }
      }

      this.allMetricValuesPerLocation = metricValuesPerLocation;

      let topTenPerLocation = this.getTopNByValue();

      let labels = Object.keys(topTenPerLocation);
      let chartLabel = this.metric + " by location" + unit;
      const backgroundColor = "#245aaa";
      let data = Object.values(topTenPerLocation);
      
      this.metricValuesPerLocation = {
        labels: labels,
        datasets: [
          {
            label: chartLabel,
            backgroundColor: backgroundColor,
            data: data,
            borderRadius: 15,
          },
        ],
      };

      return true;
    },
  },
  beforeMount() {
    this.calcMetricByLocation();
  },
};
</script>