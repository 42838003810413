<template>
    <v-container>
        <AppBar />
        <SideNav />
    </v-container>
</template>
<script>
import AppBar from './components/AppBar.vue';
import SideNav from './components/SideNav.vue';

export default {
    name: 'AppHeader',
    components: {
        AppBar,
        SideNav,
    },
}
</script>