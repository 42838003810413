<template>
    <v-btn
    class="text-none"
    text
    rounded
    color="error">
        <v-icon left>{{ btnIcon }}</v-icon>{{ btnName }}
    </v-btn>
</template>
<script>
export default {
    name: 'ErrorBtn',
    props: [
      "btnName",
      "btnIcon",
    ],
}
</script>