<template>
    <v-snackbar
        v-model="$store.state.snackbar"
        top
        right
        :color="$store.state.snackbarColor">
            {{ $store.state.snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon
                v-bind="attrs"
                @click="close()">
                    mdi-close
                </v-icon>
            </template>
        </v-snackbar>
</template>
<script>
export default {
    name: 'SnackBar',
    methods: {
        close() {
            this.$store.dispatch('closeSnackbar');
        },
    },
}
</script>
<style scoped>
::v-deep .v-snack__wrapper{
    transition-duration: 1s;
}
</style>