<template>
    <v-btn
    class="text-none"
    rounded
    :loading="loading"
    :right="right"
    :absolute="absolute"
    color="blue darken-1 white--text">
        <v-icon left>mdi-check</v-icon>{{ btnName }}
    </v-btn>
</template>
<script>
export default {
    name: 'PrimaryBtn',
    props: {
        btnName: {
            type: String,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        right: {
            type: Boolean,
            default: false,
        },
        absolute: {
            type: Boolean,
            default: false,
        },
    },
}
</script>